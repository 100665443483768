<template>
    <div>
        <Breadcrumbs main="Users" title="User Profile"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
              <div class="row">
                <!-- user profile first-style start-->
                <div class="col-sm-12">
                  <div class="card hovercard text-center">
                    <div class="cardheader"></div>
                    <div class="user-image">
                      <div class="avatar"><img alt="" src="../../assets/images/user/7.jpg"></div>
                      <div class="icon-wrapper"><i class="icofont icofont-pencil-alt-5"></i></div>
                    </div>
                    <div class="info">
                      <div class="row">
                        <div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="ttl-info text-left">
                                <h6><i class="fa fa-envelope mr-2"></i>Email</h6><span>Marekjecno@yahoo.com</span>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="ttl-info text-left">
                                <h6><i class="fa fa-calendar mr-2"></i>BOD</h6><span>02 January 1988</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                          <div class="user-designation">
                            <div class="title"><a target="_blank" href="">Mark jecno</a></div>
                            <div class="desc mt-2">designer</div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="ttl-info text-left">
                                <h6><i class="fa fa-phone mr-2"></i>Contact Us</h6><span>India +91 123-456-7890</span>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="ttl-info text-left">
                                <h6><i class="fa fa-location-arrow mr-2"></i>Location</h6><span>B69 Near Schoool Demo Home</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="social-media">
                        <ul class="list-inline">
                          <li class="list-inline-item"><a href="#"><i class="fa fa-facebook"></i></a></li>
                          <li class="list-inline-item"><a href="#"><i class="fa fa-google-plus"></i></a></li>
                          <li class="list-inline-item"><a href="#"><i class="fa fa-twitter"></i></a></li>
                          <li class="list-inline-item"><a href="#"><i class="fa fa-instagram"></i></a></li>
                          <li class="list-inline-item"><a href="#"><i class="fa fa-rss"></i></a></li>
                        </ul>
                      </div>
                      <div class="follow">
                        <div class="row">
                          <div class="col-6 text-md-right border-right">
                            <div class="follow-num counter">25869</div><span>Follower</span>
                          </div>
                          <div class="col-6 text-md-left">
                            <div class="follow-num counter">659887</div><span>Following</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- user profile first-style end-->
                <!-- user profile second-style start-->
                <div class="col-sm-12">
                  <div class="card">
                    <div class="profile-img-style">
                      <div class="row">
                        <div class="col-sm-8">
                          <div class="media"><img class="img-thumbnail rounded-circle mr-3" src="../../assets/images/user/7.jpg" alt="Generic placeholder image">
                            <div class="media-body align-self-center">
                              <h5 class="mt-0 user-name">JOHAN DIO</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 align-self-center">
                          <div class="float-sm-right"><small>10 Hours ago</small></div>
                        </div>
                      </div>
                      <hr>
                      <p>you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                      <div class="img-container">
                        <div id="aniimated-thumbnials" itemscope="">
                          <figure><a href="#"><img class="img-fluid rounded" src="../../assets/images/other-images/profile-style-img3.png"></a>
                          </figure>
                        </div>
                      </div>
                      <div class="like-comment mt-4">
                        <ul class="list-inline">
                          <li class="list-inline-item border-right pr-3">
                            <label class="m-0"><a href="#"><i class="fa fa-heart mr-2"></i></a>Like</label><span class="ml-2 counter">2659</span>
                          </li>
                          <li class="list-inline-item ml-2">
                            <label class="m-0"><a href="#"><i class="fa fa-comment mr-2"></i></a>Comment</label><span class="ml-2 counter">569</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- user profile second-style end-->
                <!-- user profile third-style start-->
                <div class="col-sm-12">
                  <div class="card">
                    <div class="profile-img-style">
                      <div class="row">
                        <div class="col-sm-8">
                          <div class="media"><img class="img-thumbnail rounded-circle mr-3" src="../../assets/images/user/7.jpg" alt="Generic placeholder image">
                            <div class="media-body align-self-center">
                              <h5 class="mt-0 user-name">JOHAN DIO</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 align-self-center">
                          <div class="float-sm-right"><small>10 Hours ago</small></div>
                        </div>
                      </div>
                      <hr>
                      <p>you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                      <div class="row mt-4 pictures" id="aniimated-thumbnials-2" itemscope="">
                        <figure class="col-sm-6"><a href="#"><img class="img-fluid rounded" src="../../assets/images/other-images/profile-style-img.png"></a>
                        </figure>
                        <figure class="col-sm-6"><a href="#"><img class="img-fluid rounded" src="../../assets/images/other-images/profile-style-img.png"></a>
                        </figure>
                      </div>
                      <div class="like-comment mt-4">
                        <ul class="list-inline">
                          <li class="list-inline-item border-right pr-3">
                            <label class="m-0"><a href="#"><i class="fa fa-heart mr-2"></i></a>Like</label><span class="ml-2 counter">2659</span>
                          </li>
                          <li class="list-inline-item ml-2">
                            <label class="m-0"><a href="#"><i class="fa fa-comment mr-2"></i></a>Comment</label><span class="ml-2 counter">569</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- user profile third-style end-->
                <!-- user profile fourth-style start-->
                <div class="col-sm-12">
                  <div class="card">
                    <div class="profile-img-style">
                      <div class="row">
                        <div class="col-sm-8">
                          <div class="media"><img class="img-thumbnail rounded-circle mr-3" src="../../assets/images/user/7.jpg" alt="Generic placeholder image">
                            <div class="media-body align-self-center">
                              <h5 class="mt-0 user-name">JOHAN DIO</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 align-self-center">
                          <div class="float-sm-right"><small>10 Hours ago</small></div>
                        </div>
                      </div>
                      <hr>
                      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source .Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source</p>
                      <div class="like-comment mt-4">
                        <ul class="list-inline">
                          <li class="list-inline-item border-right pr-3">
                            <label class="m-0"><a href="#"><i class="fa fa-heart mr-2"></i></a>Like</label><span class="ml-2 counter">2659</span>
                          </li>
                          <li class="list-inline-item ml-2">
                            <label class="m-0"><a href="#"><i class="fa fa-comment mr-2"></i></a>Comment</label><span class="ml-2 counter">569</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- user profile fourth-style end-->
                <!-- user profile fifth-style start-->
                <div class="col-sm-12">
                  <div class="card">
                    <div class="profile-img-style">
                      <div class="row">
                        <div class="col-sm-8">
                          <div class="media"><img class="img-thumbnail rounded-circle mr-3" src="../../assets/images/user/7.jpg" alt="Generic placeholder image">
                            <div class="media-body align-self-center">
                              <h5 class="mt-0 user-name">JOHAN DIO</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 align-self-center">
                          <div class="float-sm-right"><small>10 Hours ago</small></div>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-lg-12 col-xl-4">
                          <div id="aniimated-thumbnials-3" itemscope="">
                            <figure><a href="#"><img class="img-fluid rounded" src="../../assets/images/blog/img.png"></a>
                            </figure>
                          </div>
                          <div class="like-comment mt-4 like-comment-sm-mb">
                            <ul class="list-inline">
                              <li class="list-inline-item border-right pr-3">
                                <label class="m-0"><a href="#"><i class="fa fa-heart mr-2"></i></a>Like</label><span class="ml-2 counter">2659</span>
                              </li>
                              <li class="list-inline-item ml-2">
                                <label class="m-0"><a href="#"><i class="fa fa-comment mr-2"></i></a> Comment</label><span class="ml-2 counter">569</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-xl-6">
                          <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consecteturContrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- user profile fifth-style end-->
              </div>
            </div>
          </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
export default {
}
</script>