import { render, staticRenderFns } from "./blog.vue?vue&type=template&id=53dc99c3&"
import script from "./blog.vue?vue&type=script&lang=js&"
export * from "./blog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports