var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumbs',{attrs:{"main":"Bootstrap tables","title":"Styling Tables"}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{staticClass:"table-styling",attrs:{"items":_vm.items}})],1)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{attrs:{"thead-class":"table-primary","items":_vm.items}})],1)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{attrs:{"thead-class":"bg-primary","items":_vm.items}})],1)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card o-hidden"},[_vm._m(3),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{attrs:{"tfoot-tr-class":"table-primary","tbody-tr-class":_vm.rowClass,"items":_vm.items}})],1)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card o-hidden"},[_vm._m(4),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{staticClass:"table-styling table-primary",attrs:{"items":_vm.items}})],1)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card o-hidden"},[_vm._m(5),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{staticClass:"table-styling table-primary",attrs:{"hover":"","striped":"","items":_vm.items}})],1)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Defult Styling")]),_c('span',[_vm._v("use class "),_c('code',[_vm._v("table table-styling")]),_vm._v(" inside table element")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Table head options")]),_c('span',[_vm._v("Use class"),_c('code',[_vm._v(".table-primary")]),_vm._v(" inside thead tr element.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Table head options With Primary Background")]),_c('span',[_vm._v("Use background classes"),_c('code',[_vm._v(".bg-*")]),_vm._v("and "),_c('code',[_vm._v("bg-primary")]),_vm._v(","),_c('code',[_vm._v("bg-secondary")]),_vm._v(","),_c('code',[_vm._v("bg-success")]),_vm._v(","),_c('code',[_vm._v("bg-danger")]),_vm._v(" ,"),_c('code',[_vm._v("bg-info")]),_vm._v(","),_c('code',[_vm._v("bg-warning")]),_vm._v(" to make custom "),_c('code',[_vm._v("thead")]),_vm._v(" background. You can also use Stack Admin color palette classes for background.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Table Footer Styling")]),_c('span',[_vm._v("Use class "),_c('code',[_vm._v("table-info")]),_vm._v(","),_c('code',[_vm._v("table-success")]),_vm._v(","),_c('code',[_vm._v("table-success")]),_vm._v(","),_c('code',[_vm._v("table-info")]),_vm._v(","),_c('code',[_vm._v("table-danger")]),_vm._v(","),_c('code',[_vm._v("table-primary")]),_vm._v(","),_c('code',[_vm._v("table-secondary")]),_vm._v(","),_c('code',[_vm._v("table-light")]),_vm._v(","),_c('code',[_vm._v("table-active")]),_vm._v("and also use "),_c('code',[_vm._v("bg-*")]),_vm._v(" inside tfoot element.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Custom Table Color")]),_c('span',[_vm._v("Use class"),_c('code',[_vm._v("table-*")]),_vm._v(" inside table element.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Custom Table Color with Hover and Stripped")]),_c('span',[_vm._v("Use class"),_c('code',[_vm._v("table-hover, table-striped table-*")]),_c('code',[_vm._v("table-info")]),_vm._v(","),_c('code',[_vm._v("table-success")]),_vm._v(","),_c('code',[_vm._v("table-success")]),_vm._v(","),_c('code',[_vm._v("table-info")]),_vm._v(","),_c('code',[_vm._v("table-danger")]),_vm._v(","),_c('code',[_vm._v("table-primary")]),_vm._v(","),_c('code',[_vm._v("table-secondary")]),_vm._v(","),_c('code',[_vm._v("table-light")]),_vm._v(","),_c('code',[_vm._v("table-active")]),_vm._v(" inside table element.")])])
}]

export { render, staticRenderFns }