import { render, staticRenderFns } from "./ckeditor.vue?vue&type=template&id=3aaf8def&"
import script from "./ckeditor.vue?vue&type=script&lang=js&"
export * from "./ckeditor.vue?vue&type=script&lang=js&"
import style0 from "./ckeditor.vue?vue&type=style&index=0&id=3aaf8def&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports