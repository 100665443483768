<template>
  <div>
    <Breadcrumbs main="Cards" title="Creative Card"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
     <div class="row">
      
      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-l-primary">
          <h5 slot="header">Border left</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-r-secondary">
          <h5 slot="header">Border right</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-t-success">
          <h5 slot="header">Border right</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-b-info">
          <h5 slot="header">Border bottom</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-l-warning">
          <h5 slot="header">Border color state</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-l-danger">
          <h5 slot="header">Border color state</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-l-light">
          <h5 slot="header">Border color state</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-l-primary">
          <h5 slot="header">Border color state</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-l-secondary border-2">
          <h5 slot="header">Border color state</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-class="b-l-primary border-3">
          <h5 slot="header">Border color state</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-bg-variant="primary" class="card-absolute">
          <h5 slot="header">Absolute Style</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

      <div class="col-sm-12 col-xl-6">
        <b-card header-tag="div" header-bg-variant="secondary" class="card-absolute">
          <h5 slot="header">Color state</h5>
          <b-card-text class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</b-card-text>
        </b-card>
      </div>

    </div>
  </div>
  <!-- Container-fluid Ends-->
</div>
</template>

<script>
export default {
}
</script>