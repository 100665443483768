var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumbs',{attrs:{"main":"Ecommerce","title":"Wishlist"}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"order-history table-responsive wishlist"},[_c('table',{staticClass:"table table-bordernone"},[_vm._m(1),_c('tbody',[_vm._m(2),_c('tr',[_vm._m(3),_vm._m(4),_c('td',[_vm._v("$21")]),_c('td',[_vm._v("In Stock")]),_c('td',[_c('feather',{attrs:{"type":"x-circle"}})],1)]),_c('tr',[_vm._m(5),_vm._m(6),_c('td',[_vm._v("$50")]),_c('td',[_vm._v("In Stock")]),_c('td',[_c('feather',{attrs:{"type":"x-circle"}})],1)]),_c('tr',[_vm._m(7),_vm._m(8),_c('td',[_vm._v("$11")]),_c('td',[_vm._v("In Stock")]),_c('td',[_c('feather',{attrs:{"type":"x-circle"}})],1)])])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Wishlist")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Prdouct")]),_c('th',[_vm._v("Prdouct Name")]),_c('th',[_vm._v("Price")]),_c('th',[_vm._v("Availability")]),_c('th',[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"title-orders"},[_c('td',{attrs:{"colspan":"12"}},[_vm._v("New Orders")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{staticClass:"img-fluid img-60",attrs:{"src":require("../../assets/images/product/1.png"),"alt":"#"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"product-name"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Long Top")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{staticClass:"img-fluid img-60",attrs:{"src":require("../../assets/images/product/13.png"),"alt":"#"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"product-name"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Fancy watch")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('img',{staticClass:"img-fluid img-60",attrs:{"src":require("../../assets/images/product/4.png"),"alt":"#"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"product-name"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Man shoes")])])])
}]

export { render, staticRenderFns }