var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"auth-bg"},[_c('div',{staticClass:"authentication-box"},[_vm._m(0),_c('div',{staticClass:"card mt-4 p-4"},[_c('h4',{staticClass:"text-center"},[_vm._v("NEW USER")]),_c('h6',{staticClass:"text-center"},[_vm._v("Enter your Username and Password For Signup")]),_c('form',{staticClass:"theme-form"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-sm-4"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.signUp}},[_vm._v("Sign Up")])]),_vm._m(6)]),_c('div',{staticClass:"form-divider"}),_vm._m(7)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("../../assets/images/endless-logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("First Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"John"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Last Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Deo"}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("User Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"John Deo"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Email")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","placeholder":"John Deo"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"autocomplete":"","type":"password","placeholder":"**********"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("DOB")]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-sm-4"},[_c('select',{staticClass:"form-control mb-1"},[_c('option',[_vm._v("DD")]),_c('option',[_vm._v("01")]),_c('option',[_vm._v("02")]),_c('option',[_vm._v("03")]),_c('option',[_vm._v("04")])])]),_c('div',{staticClass:"col-sm-4"},[_c('select',{staticClass:"form-control mb-1"},[_c('option',[_vm._v("MM")]),_c('option',[_vm._v("01")]),_c('option',[_vm._v("02")]),_c('option',[_vm._v("03")]),_c('option',[_vm._v("04")])])]),_c('div',{staticClass:"col-sm-4"},[_c('select',{staticClass:"form-control mb-1"},[_c('option',[_vm._v("YYYY")]),_c('option',[_vm._v("1990")]),_c('option',[_vm._v("1991")]),_c('option',[_vm._v("1992")]),_c('option',[_vm._v("1993")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"text-left mt-2 m-l-20"},[_vm._v("Are you already user? "),_c('a',{staticClass:"btn-link text-capitalize",attrs:{"href":"login.html"}},[_vm._v("Login")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"social mt-3"},[_c('div',{staticClass:"form-group btn-showcase d-flex"},[_c('button',{staticClass:"btn social-btn btn-fb d-inline-block"},[_c('i',{staticClass:"fa fa-facebook"})]),_c('button',{staticClass:"btn social-btn btn-twitter d-inline-block"},[_c('i',{staticClass:"fa fa-google"})]),_c('button',{staticClass:"btn social-btn btn-google d-inline-block"},[_c('i',{staticClass:"fa fa-twitter"})]),_c('button',{staticClass:"btn social-btn btn-github d-inline-block"},[_c('i',{staticClass:"fa fa-github"})])])])
}]

export { render, staticRenderFns }