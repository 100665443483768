<template>
    <div>
        <Breadcrumbs title="tree"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">

                <div class="col-sm-12 col-xl-6">
                    <div class="card">
                        <div class="card-header">
                            <h5>Basic Tree</h5>
                        </div>
                        <div class="card-body">
                            <div id="basicTree">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Container-fluid Ends-->
    </div>
</template>

<script>

export default {
    data(){
        return{
        }
    },
    components: {
    }
}
</script>