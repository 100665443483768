<template>
<div>
<Breadcrumbs main="Buttons" title="Button Group"/>
<!-- Container-fluid starts-->
	<div class="container-fluid">
	<div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>Basic button group</h5>
                </div>
                <div class="card-body btn-group-showcase">
                    <div class="row">

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="primary">Left</b-button>
                          <b-button variant="primary">Middle</b-button>
                          <b-button variant="primary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="secondary">Left</b-button>
                          <b-button variant="secondary">Middle</b-button>
                          <b-button variant="secondary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="success">Left</b-button>
                          <b-button variant="success">Middle</b-button>
                          <b-button variant="success">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="info">Left</b-button>
                          <b-button variant="info">Middle</b-button>
                          <b-button variant="info">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="warning">Left</b-button>
                          <b-button variant="warning">Middle</b-button>
                          <b-button variant="warning">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="danger">Left</b-button>
                          <b-button variant="danger">Middle</b-button>
                          <b-button variant="danger">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="light">Left</b-button>
                          <b-button variant="light">Middle</b-button>
                          <b-button variant="light">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="dark">Left</b-button>
                          <b-button variant="dark">Middle</b-button>
                          <b-button variant="dark">Right</b-button>
                        </b-button-group>
                      </div>

                    </div>
                </div>
            </div>
		</div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>Edges button group</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="primary">Left</b-button>
                          <b-button variant="primary">Middle</b-button>
                          <b-button variant="primary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="secondary">Left</b-button>
                          <b-button variant="secondary">Middle</b-button>
                          <b-button variant="secondary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="success">Left</b-button>
                          <b-button variant="success">Middle</b-button>
                          <b-button variant="success">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="info">Left</b-button>
                          <b-button variant="info">Middle</b-button>
                          <b-button variant="info">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="warning">Left</b-button>
                          <b-button variant="warning">Middle</b-button>
                          <b-button variant="warning">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="danger">Left</b-button>
                          <b-button variant="danger">Middle</b-button>
                          <b-button variant="danger">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="light">Left</b-button>
                          <b-button variant="light">Middle</b-button>
                          <b-button variant="light">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="dark">Left</b-button>
                          <b-button variant="dark">Middle</b-button>
                          <b-button variant="dark">Right</b-button>
                        </b-button-group>
                      </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>Flat button group</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="primary">Left</b-button>
                          <b-button variant="primary">Middle</b-button>
                          <b-button variant="primary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="secondary">Left</b-button>
                          <b-button variant="secondary">Middle</b-button>
                          <b-button variant="secondary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="success">Left</b-button>
                          <b-button variant="success">Middle</b-button>
                          <b-button variant="success">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="info">Left</b-button>
                          <b-button variant="info">Middle</b-button>
                          <b-button variant="info">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="warning">Left</b-button>
                          <b-button variant="warning">Middle</b-button>
                          <b-button variant="warning">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="danger">Left</b-button>
                          <b-button variant="danger">Middle</b-button>
                          <b-button variant="danger">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="light">Left</b-button>
                          <b-button variant="light">Middle</b-button>
                          <b-button variant="light">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="dark">Left</b-button>
                          <b-button variant="dark">Middle</b-button>
                          <b-button variant="dark">Right</b-button>
                        </b-button-group>
                      </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>large Button group</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="primary" class="btn-lg">Left</b-button>
                          <b-button variant="primary" class="btn-lg">Middle</b-button>
                          <b-button variant="primary" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="secondary" class="btn-lg">Left</b-button>
                          <b-button variant="secondary" class="btn-lg">Middle</b-button>
                          <b-button variant="secondary" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="success" class="btn-lg">Left</b-button>
                          <b-button variant="success" class="btn-lg">Middle</b-button>
                          <b-button variant="success" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="info" class="btn-lg">Left</b-button>
                          <b-button variant="info" class="btn-lg">Middle</b-button>
                          <b-button variant="info" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="warning" class="btn-lg">Left</b-button>
                          <b-button variant="warning" class="btn-lg">Middle</b-button>
                          <b-button variant="warning" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="danger" class="btn-lg">Left</b-button>
                          <b-button variant="danger" class="btn-lg">Middle</b-button>
                          <b-button variant="danger" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="light" class="btn-lg">Left</b-button>
                          <b-button variant="light" class="btn-lg">Middle</b-button>
                          <b-button variant="light" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="dark" class="btn-lg">Left</b-button>
                          <b-button variant="dark" class="btn-lg">Middle</b-button>
                          <b-button variant="dark" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>large Edges Button group</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="primary" class="btn-lg">Left</b-button>
                          <b-button variant="primary" class="btn-lg">Middle</b-button>
                          <b-button variant="primary" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="secondary" class="btn-lg">Left</b-button>
                          <b-button variant="secondary" class="btn-lg">Middle</b-button>
                          <b-button variant="secondary" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="success" class="btn-lg">Left</b-button>
                          <b-button variant="success" class="btn-lg">Middle</b-button>
                          <b-button variant="success" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="info" class="btn-lg">Left</b-button>
                          <b-button variant="info" class="btn-lg">Middle</b-button>
                          <b-button variant="info" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="warning" class="btn-lg">Left</b-button>
                          <b-button variant="warning" class="btn-lg">Middle</b-button>
                          <b-button variant="warning" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="danger" class="btn-lg">Left</b-button>
                          <b-button variant="danger" class="btn-lg">Middle</b-button>
                          <b-button variant="danger" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="light" class="btn-lg">Left</b-button>
                          <b-button variant="light" class="btn-lg">Middle</b-button>
                          <b-button variant="light" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="dark" class="btn-lg">Left</b-button>
                          <b-button variant="dark" class="btn-lg">Middle</b-button>
                          <b-button variant="dark" class="btn-lg">Right</b-button>
                        </b-button-group>
                      </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>Outline Custom button group</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-primary">Left</b-button>
                          <b-button variant="primary">Middle</b-button>
                          <b-button variant="outline-primary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-secondary">Left</b-button>
                          <b-button variant="secondary">Middle</b-button>
                          <b-button variant="outline-secondary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-success">Left</b-button>
                          <b-button variant="success">Middle</b-button>
                          <b-button variant="outline-success">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-info">Left</b-button>
                          <b-button variant="info">Middle</b-button>
                          <b-button variant="outline-info">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-warning">Left</b-button>
                          <b-button variant="warning">Middle</b-button>
                          <b-button variant="outline-warning">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-danger">Left</b-button>
                          <b-button variant="danger">Middle</b-button>
                          <b-button variant="outline-danger">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-light">Left</b-button>
                          <b-button variant="light">Middle</b-button>
                          <b-button variant="outline-light">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-dark">Left</b-button>
                          <b-button variant="dark">Middle</b-button>
                          <b-button variant="outline-dark">Right</b-button>
                        </b-button-group>
                      </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>Outline Custom button group</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="primary">Left</b-button>
                          <b-button variant="outline-primary">Middle</b-button>
                          <b-button variant="primary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="secondary">Left</b-button>
                          <b-button variant="outline-secondary">Middle</b-button>
                          <b-button variant="secondary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="success">Left</b-button>
                          <b-button variant="outline-success">Middle</b-button>
                          <b-button variant="success">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="info">Left</b-button>
                          <b-button variant="outline-info">Middle</b-button>
                          <b-button variant="info">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="warning">Left</b-button>
                          <b-button variant="outline-warning">Middle</b-button>
                          <b-button variant="warning">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="danger">Left</b-button>
                          <b-button variant="outline-danger">Middle</b-button>
                          <b-button variant="danger">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="light">Left</b-button>
                          <b-button variant="outline-light">Middle</b-button>
                          <b-button variant="light">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="dark">Left</b-button>
                          <b-button variant="outline-dark">Middle</b-button>
                          <b-button variant="dark">Right</b-button>
                        </b-button-group>
                      </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>Outline button group</h5>
                </div>
                <div class="card-body btn-group-showcase">
                    <div class="row">

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="outline-primary">Left</b-button>
                          <b-button variant="outline-primary">Middle</b-button>
                          <b-button variant="outline-primary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="outline-secondary">Left</b-button>
                          <b-button variant="outline-secondary">Middle</b-button>
                          <b-button variant="outline-secondary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="outline-success">Left</b-button>
                          <b-button variant="outline-success">Middle</b-button>
                          <b-button variant="outline-success">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="outline-info">Left</b-button>
                          <b-button variant="outline-info">Middle</b-button>
                          <b-button variant="outline-info">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="outline-warning">Left</b-button>
                          <b-button variant="outline-warning">Middle</b-button>
                          <b-button variant="outline-warning">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="outline-danger">Left</b-button>
                          <b-button variant="outline-danger">Middle</b-button>
                          <b-button variant="outline-danger">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="outline-light">Left</b-button>
                          <b-button variant="outline-light">Middle</b-button>
                          <b-button variant="outline-light">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group>
                          <b-button variant="outline-dark">Left</b-button>
                          <b-button variant="outline-dark">Middle</b-button>
                          <b-button variant="outline-dark">Right</b-button>
                        </b-button-group>
                      </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>Outline Edges button</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-primary">Left</b-button>
                          <b-button variant="outline-primary">Middle</b-button>
                          <b-button variant="outline-primary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-secondary">Left</b-button>
                          <b-button variant="outline-secondary">Middle</b-button>
                          <b-button variant="outline-secondary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-success">Left</b-button>
                          <b-button variant="outline-success">Middle</b-button>
                          <b-button variant="outline-success">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-info">Left</b-button>
                          <b-button variant="outline-info">Middle</b-button>
                          <b-button variant="outline-info">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-warning">Left</b-button>
                          <b-button variant="outline-warning">Middle</b-button>
                          <b-button variant="outline-warning">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-danger">Left</b-button>
                          <b-button variant="outline-danger">Middle</b-button>
                          <b-button variant="outline-danger">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-light">Left</b-button>
                          <b-button variant="outline-light">Middle</b-button>
                          <b-button variant="outline-light">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-pill">
                          <b-button variant="outline-dark">Left</b-button>
                          <b-button variant="outline-dark">Middle</b-button>
                          <b-button variant="outline-dark">Right</b-button>
                        </b-button-group>
                      </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>Outline flat button</h5>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="outline-primary">Left</b-button>
                          <b-button variant="outline-primary">Middle</b-button>
                          <b-button variant="outline-primary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="outline-secondary">Left</b-button>
                          <b-button variant="outline-secondary">Middle</b-button>
                          <b-button variant="outline-secondary">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="outline-success">Left</b-button>
                          <b-button variant="outline-success">Middle</b-button>
                          <b-button variant="outline-success">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="outline-info">Left</b-button>
                          <b-button variant="outline-info">Middle</b-button>
                          <b-button variant="outline-info">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="outline-warning">Left</b-button>
                          <b-button variant="outline-warning">Middle</b-button>
                          <b-button variant="outline-warning">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="outline-danger">Left</b-button>
                          <b-button variant="outline-danger">Middle</b-button>
                          <b-button variant="outline-danger">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="outline-light">Left</b-button>
                          <b-button variant="outline-light">Middle</b-button>
                          <b-button variant="outline-light">Right</b-button>
                        </b-button-group>
                      </div>

                      <div class="col-xl-4 col-md-6 col-sm-12">
                        <b-button-group class="btn-group-square">
                          <b-button variant="outline-dark">Left</b-button>
                          <b-button variant="outline-dark">Middle</b-button>
                          <b-button variant="outline-dark">Right</b-button>
                        </b-button-group>
                      </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h5>Radio button group</h5><span>Add <code>.active</code> for active state and<code>.disabled</code> class or <code>disabled="disabled"</code> attribute</span>
              </div>
              <div class="card-body btn-group-showcase">
                <div class="row">

                    <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                        <div class="btn btn-primary">
                            <div class="radio radio-primary">
                                <b-form-radio-group>
                                    <b-form-radio name="radio1" value="option1">Option 1</b-form-radio>
                                    <b-form-radio name="radio1" value="option2">Option 2</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                        <div class="btn btn-secondary">
                            <div class="radio radio-secondary">
                                <b-form-radio-group>
                                    <b-form-radio name="radio2" value="option1">Option 1</b-form-radio>
                                    <b-form-radio name="radio2" value="option2">Option 2</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                        <div class="btn btn-success">
                            <div class="radio radio-success">
                                <b-form-radio-group>
                                    <b-form-radio name="radio3" value="option1">Option 1</b-form-radio>
                                    <b-form-radio name="radio3" value="option2">Option 2</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                        <div class="btn btn-info">
                            <div class="radio radio-info">
                                <b-form-radio-group>
                                    <b-form-radio name="radio4" value="option1">Option 1</b-form-radio>
                                    <b-form-radio name="radio4" value="option2">Option 2</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                        <div class="btn btn-warning">
                            <div class="radio radio-warning">
                                <b-form-radio-group>
                                    <b-form-radio name="radio5" value="option1">Option 1</b-form-radio>
                                    <b-form-radio name="radio5" value="option2">Option 2</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                        <div class="btn btn-danger">
                            <div class="radio radio-danger">
                                <b-form-radio-group>
                                    <b-form-radio name="radio6" value="option1">Option 1</b-form-radio>
                                    <b-form-radio name="radio6" value="option2">Option 2</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                        <div class="btn btn-light">
                            <div class="radio radio-light">
                                <b-form-radio-group>
                                    <b-form-radio name="radio7" value="option1">Option 1</b-form-radio>
                                    <b-form-radio name="radio7" value="option2">Option 2</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>
                    </div>

                </div>
              </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>Check Box button group</h5><span>Add <code>.active</code> for active state and <code>.disabled</code> class or <code>disabled="disabled"</code> attribute</span>
                </div>
                <div class="card-body btn-group-showcase">
                    <div class="row">

                        <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                            <div class="btn btn-primary">
                                <div class="checkbox checkbox-primary">
                                    <b-form-checkbox-group>
                                        <b-form-checkbox name="checkbox1" value="option1">Option1</b-form-checkbox>
                                        <b-form-checkbox name="checkbox1" value="option2">Option2</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                            <div class="btn btn-secondary">
                                <div class="checkbox checkbox-secondary">
                                    <b-form-checkbox-group>
                                        <b-form-checkbox name="checkbox2" value="option1">Option1</b-form-checkbox>
                                        <b-form-checkbox name="checkbox2" value="option2">Option2</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                            <div class="btn btn-success">
                                <div class="checkbox checkbox-success">
                                    <b-form-checkbox-group>
                                        <b-form-checkbox name="checkbox3" value="option1">Option1</b-form-checkbox>
                                        <b-form-checkbox name="checkbox3" value="option2">Option2</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                            <div class="btn btn-info">
                                <div class="checkbox checkbox-info">
                                    <b-form-checkbox-group>
                                        <b-form-checkbox name="checkbox4" value="option1">Option1</b-form-checkbox>
                                        <b-form-checkbox name="checkbox4" value="option2">Option2</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                            <div class="btn btn-warning">
                                <div class="checkbox checkbox-warning">
                                    <b-form-checkbox-group>
                                        <b-form-checkbox name="checkbox5" value="option1">Option1</b-form-checkbox>
                                        <b-form-checkbox name="checkbox5" value="option2">Option2</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                            <div class="btn btn-danger">
                                <div class="checkbox checkbox-danger">
                                    <b-form-checkbox-group>
                                        <b-form-checkbox name="checkbox6" value="option1">Option1</b-form-checkbox>
                                        <b-form-checkbox name="checkbox6" value="option2">Option2</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6 col-sm-12 mb-4">
                            <div class="btn btn-light">
                                <div class="checkbox checkbox-light">
                                    <b-form-checkbox-group>
                                        <b-form-checkbox name="checkbox7" value="option1">Option1</b-form-checkbox>
                                        <b-form-checkbox name="checkbox7" value="option2">Option2</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="card height-equal">
              <div class="card-header">
                <h5>Nesting</h5><span>Make nesting buttons</span>
              </div>
              <div class="card-body btn-group-wrapper">
                <div class="m-b-30">
                    <b-button-group>
                        <b-button variant="primary"><i class="fa fa-bold"></i></b-button>
                        <b-button variant="secondary"><i class="fa fa fa-italic"></i></b-button>
                        <b-dropdown right text="Dropdown" variant="light">
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                        </b-dropdown>
                    </b-button-group>
                </div>
                <div class="m-b-30">
                    <b-button-group>
                        <b-button variant="primary"><i class="fa fa-bold"></i></b-button>
                        <b-button variant="secondary"><i class="fa fa fa-italic"></i></b-button>
                        <b-button variant="success"><i class="fa fa-file-image-o"></i></b-button>
                        <b-button variant="info"><i class="fa fa-paperclip"></i></b-button>
                    </b-button-group>
                </div>
                <div class="m-b-30">
                    <b-button-group>
                        <b-button variant="primary" class="btn-lg"><i class="fa fa-bold"></i></b-button>
                        <b-button variant="secondary" class="btn-lg"><i class="fa fa fa-italic"></i></b-button>
                        <b-button variant="success" class="btn-lg"><i class="fa fa-file-image-o"></i></b-button>
                        <b-button variant="info" class="btn-lg"><i class="fa fa-paperclip"></i></b-button>
                    </b-button-group>
                </div>
              </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="card height-equal">
              <div class="card-header">
                <h5>Vertical</h5><span>Make vertical buttons</span>
              </div>
              <div class="card-body btn-group-wrapper">
                <div class="m-b-30">
                    <b-button-group vertical>
                        <b-button variant="primary">Button</b-button>
                        <b-button variant="secondary">Button</b-button>
                        <b-dropdown text="Dropdown" variant="success">
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                        </b-dropdown>
                        <b-button variant="info">Button</b-button>
                        <b-button variant="warning">Button</b-button>
                        <b-dropdown text="Dropdown" variant="danger">
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                        </b-dropdown>
                        <b-dropdown text="Dropdown" variant="light">
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                          <b-dropdown-item>Dropdown link</b-dropdown-item>
                        </b-dropdown>
                    </b-button-group>
                </div>
              </div>
            </div>
        </div>

	</div>
</div>
<!-- Container-fluid Ends-->
</div>
</template>

<script>
export default {

}
</script>