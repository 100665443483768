<template>
	<div>
		<Breadcrumbs main="Base" title="State Color"></Breadcrumbs>
		<!-- Container-fluid starts-->
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
        <div class="card">
                  <div class="card-header">
                    <h5>Default Color</h5>
                  </div>
                  <div class="card-body">
                    <div class="color-box">
                      <button class="btn btn-primary btn-square digits">#ab8ce4</button>
                      <button class="btn btn-square digits btn-secondary">#26c6da</button>
                      <button class="btn btn-square digits btn-success">#00c292</button>
                      <button class="btn btn-square digits btn-info">#03a9f3</button>
                      <button class="btn btn-square digits btn-warning">#fec107</button>
                      <button class="btn btn-square digits btn-danger">#fb9678</button>
                      <button class="btn btn-square digits btn-light">#eeeeee</button>
                      <button class="btn btn-square digits btn-dark">#263238</button>
                    </div>
                  </div>
                </div>
          <div class="card">
            <div class="card-header">
              <h5>Color</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-3 col-sm-6">
                  <h6 class="sub-title text-uppercase">Primary</h6>
                  <div class="primary-color">
                    <ul class="m-b-30">
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <h6 class="sub-title text-uppercase">secondary</h6>
                  <div class="secondary-color">
                    <ul class="m-b-30">
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <h6 class="sub-title text-uppercase">Success</h6>
                  <div class="success-color">
                    <ul class="m-b-30">
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <h6 class="sub-title text-uppercase">Info</h6>
                  <div class="info-color">
                    <ul class="m-b-30">
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <h6 class="sub-title text-uppercase">Warning</h6>
                  <div class="yellow-color">
                    <ul>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 xs-mt">
                  <h6 class="sub-title text-uppercase">Danger</h6>
                  <div class="red-color">
                    <ul>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 nav-md-mt">
                  <h6 class="sub-title text-uppercase">Pink</h6>
                  <div class="pink-color">
                    <ul>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 nav-md-mt">
                  <h6 class="sub-title text-uppercase">Grey</h6>
                  <div class="gray-color">
                    <ul>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                      <li><span></span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>