var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumbs',{attrs:{"main":"Maps","title":"Google Maps"}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('GmapMap',{staticStyle:{"width":"auto","height":"500px"},attrs:{"center":{lat:20.5937, lng:78.9629},"zoom":12}})],1)])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('GmapMap',{staticStyle:{"width":"auto","height":"500px"},attrs:{"center":{lat:20.5937, lng:78.9629},"zoom":6,"options":{
                      region: 'VI',
                      language: 'vi',
                  }}})],1)])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_c('Markers')],1)])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_c('DraggableMarker')],1)])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_vm._m(4),_c('div',{staticClass:"card-body"},[_c('GooglePolyline')],1)])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_vm._m(5),_c('div',{staticClass:"card-body"},[_c('TrianglePolygon')],1)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Map at a specified location")]),_c('span',[_vm._v("Display a map at a specified location and zoom level.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Multi-language support")]),_c('span',[_vm._v("Display a map with labels in a foreign language")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Marker on the Map")]),_c('span',[_vm._v("Display a map highlighting points of interest")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Draggable Marker")]),_c('span',[_vm._v("Display a map highlighting points of interest")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Polyline on the Map")]),_c('span',[_vm._v("Display a map with a line showing a known route")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Polygon on the Map")]),_c('span',[_vm._v("Display a map highlighting a region or area")])])
}]

export { render, staticRenderFns }