<template>
	<div>
		<Breadcrumbs main="Timeline" title="Timeline 2"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid">
			<div class="row">

				<div class="col-xl-4 xl-50">
					<div class="card">
						<div class="card-header">
							<h5>Timeline Primary color</h5>
						</div>
						<div class="card-body">
							<div class="timeline-small">
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-primary"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">New</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-primary"><feather type="message-circle"></feather></div>
									<div class="media-body">
										<h6>New Message <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 small-line bg-primary"><feather type="minus-circle"></feather></div>
									<div class="media-body">
										<h6>New Report <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-primary"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 medium-line bg-primary"><feather type="tag"></feather></div>
									<div class="media-body">
										<h6>New Visits <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-4 xl-50">
					<div class="card">
						<div class="card-header">
							<h5>Timeline secondary color</h5>
						</div>
						<div class="card-body">
							<div class="timeline-small">
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-secondary"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">New</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-secondary"><feather type="message-circle"></feather></div>
									<div class="media-body">
										<h6>New Message <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 small-line bg-secondary"><feather type="minus-circle"></feather></div>
									<div class="media-body">
										<h6>New Report <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-secondary"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 medium-line bg-secondary"><feather type="tag"></feather></div>
									<div class="media-body">
										<h6>New Visits <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-4 xl-50">
					<div class="card">
						<div class="card-header">
							<h5>Timeline Success color</h5>
						</div>
						<div class="card-body">
							<div class="timeline-small">
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-success"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">New</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-success"><feather type="message-circle"></feather></div>
									<div class="media-body">
										<h6>New Message <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 small-line bg-success"><feather type="minus-circle"></feather></div>
									<div class="media-body">
										<h6>New Report <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-success"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 medium-line bg-success"><feather type="tag"></feather></div>
									<div class="media-body">
										<h6>New Visits <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-4 xl-50">
					<div class="card">
						<div class="card-header">
							<h5>Timeline Info color</h5>
						</div>
						<div class="card-body">
							<div class="timeline-small">
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-info"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">New</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-info"><feather type="message-circle"></feather></div>
									<div class="media-body">
										<h6>New Message <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 small-line bg-info"><feather type="minus-circle"></feather></div>
									<div class="media-body">
										<h6>New Report <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-info"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 medium-line bg-info"><feather type="tag"></feather></div>
									<div class="media-body">
										<h6>New Visits <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-4 xl-50">
					<div class="card">
						<div class="card-header">
							<h5>Timeline Warning color</h5>
						</div>
						<div class="card-body">
							<div class="timeline-small">
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-warning"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">New</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-warning"><feather type="message-circle"></feather></div>
									<div class="media-body">
										<h6>New Message <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 small-line bg-warning"><feather type="minus-circle"></feather></div>
									<div class="media-body">
										<h6>New Report <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-warning"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 medium-line bg-warning"><feather type="tag"></feather></div>
									<div class="media-body">
										<h6>New Visits <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-4 xl-50">
					<div class="card">
						<div class="card-header">
							<h5>Timeline Danger color</h5>
						</div>
						<div class="card-body">
							<div class="timeline-small">
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-danger"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">New</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-danger"><feather type="message-circle"></feather></div>
									<div class="media-body">
										<h6>New Message <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 small-line bg-danger"><feather type="minus-circle"></feather></div>
									<div class="media-body">
										<h6>New Report <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 timeline-line-1 bg-danger"><feather type="shopping-bag"></feather></div>
									<div class="media-body">
										<h6>New Sale <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
								<div class="media">
									<div class="timeline-round m-r-30 medium-line bg-danger"><feather type="tag"></feather></div>
									<div class="media-body">
										<h6>New Visits <span class="pull-right f-14">14m Ago</span></h6>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<!-- Container-fluid Ends-->
	</div>
</template>

<script>
export default {
}
</script>